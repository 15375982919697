import { GetCommunityDetailsFeaturesAmenitiesAnchor } from './services/SearchAPI/types';

export const JUMIO_NON_SELFIE_FLOW = '10027';
export const JUMIO_SELFIE_FLOW = '10013';

export const JWT_USER_ROLE = 'https://sgt.user.role';

export const JWT_USER_EMAIL = 'https://sgt.user.email';

export const DEFAULT_AGENT_ID = 'NO_AGENT';

export const MISSING_IMAGE = 'https://cdn.irvinecompany.com/mobile/tourguide/placeholder.jpg';

export const DEFAULT_PET_POLICY_DATA: GetCommunityDetailsFeaturesAmenitiesAnchor = {
  link: '#',
  title: 'Pet Policy',
  subtitle: 'Pet Policy Details and Restrictions',
  content: `<h4>PET MAXIMUM</h4>\n<p>2 pet maximum per apartment home</p>\n<h4>DEPOSITS &amp; FEES</h4>\n<p>Cat - $300 per apartment; $50 monthly<br>\npremium per cat.<br>\n<br>\nDog - $750 per apartment; $75 monthly<br>\npremium per dog.</p>\n<h4>BREED RESTRICTIONS:</h4>\n<p>Pit Bulls, American Staffordshire Terriers, American Bulldogs, Rottweilers, Doberman Pinchers, Wolf-Hybrids and Perro de Presa Canarios or any dog that has the above breeds in their lineage are not allowed at any Irvine Company apartment communities.<br>\n<br>\n* In select floor plans/residences</p>\n`,
};

export enum KeylessProvider {
  NONE = 'none',
  BRIVO = 'brivo',
  KASTLE = 'kastle',
  KEYPAD = 'keypad',
}

export const FEET_PER_METER = 3.28084;
