import * as yup from 'yup';

import {
  CommunityTile,
  Feature,
  KeylessEntry,
  MapData,
  MarkerProperties,
  MarkerType,
  PivotalData,
  PropertyUnits,
  Prospect,
  RouteProperties,
  TourMap,
  UnitAvailability,
} from '../../_generated/types';

import { PropertyEditorMode } from '../redux/models/map';
import _, { omit, pick } from 'lodash';
import * as R from 'ramda';
import { InputObject } from '../hooks/useMarkerPropertyEditor';
import moment from 'moment';
import strings from '../constants/strings';
import { ApolloError } from '@apollo/client';
import { KeylessProvider } from '../../server/constants';
import { GetKeylessEntriesByApartmentAndPropertyIDsQuery } from '../graphql/graphql';
import { KeylessErrorsType } from '../../pages/tour/[tourId]';
import { OccupancyStatus, Tile } from '../../types';

export const isKeylessAccessibleUnit = (feat: Feature) => {
  return isUnit(feat) || isModelUnit(feat);
};

export const isTestTourId = (tourId: string) =>
  /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(tourId);

export const filterOutTestTourMaps = (tourMaps: TourMap[]) =>
  tourMaps.filter((tourMap: TourMap) => !isTestTourId(tourMap.tourId ?? ''));

export const isUnit = (feat: Feature) => {
  const properties = feat.properties as MarkerProperties;
  return properties.markerType === MarkerType.Unit;
};
export const isModelUnit = (feat: Feature) => {
  const properties = feat.properties as MarkerProperties;
  return properties.markerType === MarkerType.ModelUnit;
};
export const isStart = (feat: Feature) => {
  const properties = feat.properties as MarkerProperties;
  return properties.markerType === MarkerType.Start;
};
export const isFutureUnit = (feat: Feature) => {
  const properties = feat.properties as MarkerProperties;
  return properties.markerType === MarkerType.FutureUnit;
};
export const isAvailableUnit = (unit: UnitAvailability, markerType?: MarkerType) => {
  if (markerType === MarkerType.FutureUnit) {
    return [OccupancyStatus.VACANT_NOT_READY, OccupancyStatus.ON_NOTICE, OccupancyStatus.NA].some(
      (status) => status === unit?.availability,
    );
  } else {
    return [OccupancyStatus.VACANT_READY, OccupancyStatus.IN_CONSTRUCTION, OccupancyStatus.VA].some(
      (status) => status === unit.availability,
    );
  }
};

export const validateInput = (mode: PropertyEditorMode, input: InputObject, setError: (msg: string) => void) => {
  try {
    if (mode === PropertyEditorMode.ORDER) {
      const schema = yup
        .number()
        .integer('Order must be a positive integer')
        .positive('Order must be a positive integer')
        .typeError('Order must be a positive integer');
      schema.validateSync(input.order);
      return true;
    }
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      setError(err.message);
    } else {
      setError('Error validating input');
    }
    return false;
  }
  return true;
};

export const removeUnavailableUnitIDs = (mapData: MapData | undefined, propertyUnits: PropertyUnits[]) => {
  const features = mapData?.features;
  const allUnits = propertyUnits.map((property) => property.units).reduce(R.concat, []);
  return features
    ? {
        ...mapData,
        features: features.map((feat) => {
          const properties = feat.properties as MarkerProperties;
          if (properties.markerType !== MarkerType.Unit && properties.markerType !== MarkerType.FutureUnit) {
            return feat;
          }
          const isAvailableBuilding = allUnits.some(
            (unit) => unit?.id === properties.apartmentId && isAvailableUnit(unit, properties.markerType!),
          );
          return isAvailableBuilding ? feat : omit(feat, ['properties.apartmentId', 'properties.isKeyless']);
        }),
      }
    : mapData;
};

export const removeUnavailableUnits = (mapData: MapData | undefined) => {
  const features =
    mapData?.features?.filter((f) => {
      const props = f.properties as MarkerProperties;
      if (
        props.markerType &&
        [MarkerType.Unit, MarkerType.ModelUnit, MarkerType.LeasedUnit, MarkerType.FutureUnit].includes(props.markerType)
      ) {
        return props.apartmentId;
      }
      return f;
    }) || [];
  return { ...mapData, features };
};

export const getDurationInMinutes = (startTime: Date | undefined) => {
  if (startTime) {
    return Math.floor(Math.abs((new Date().getTime() - startTime.getTime()) / 1000) / 60);
  }
};

export const getDurationInMinutesBetweenTimes = (startTime: Date, endTime: Date) =>
  Math.floor(Math.abs((endTime.getTime() - startTime.getTime()) / 1000));

export const isMpDestination = (markerType: MarkerType | undefined | null) =>
  !markerType
    ? false
    : [
        MarkerType.Start,
        MarkerType.FutureUnit,
        MarkerType.General,
        MarkerType.LeasedUnit,
        MarkerType.ModelUnit,
        MarkerType.Unit,
        MarkerType.Amenity,
      ].includes(markerType);

export const isKeylessAccessibleAmenity = (markerType: MarkerType) =>
  markerType === MarkerType.Amenity ||
  markerType === MarkerType.Elevator ||
  markerType === MarkerType.Gate ||
  markerType === MarkerType.General ||
  markerType === MarkerType.AccessDoor ||
  markerType === MarkerType.Stairs;

export const isAmenity = (markerType: MarkerType) =>
  isKeylessAccessibleAmenity(markerType) || markerType === MarkerType.Wheelchair;

export const isUnitMarker = (markerType: MarkerType) =>
  markerType === MarkerType.Unit || markerType === MarkerType.ModelUnit || markerType === MarkerType.FutureUnit;

export const isAnyUnitType = (markerType: MarkerType) =>
  [MarkerType.Unit, MarkerType.ModelUnit, MarkerType.FutureUnit, MarkerType.LeasedUnit].includes(markerType);

export const stripUnavailableUnitsFromMapData = ({
  mapData,
  unitsByProperty,
}: {
  mapData: MapData | undefined | null;
  unitsByProperty: PropertyUnits[];
}) => {
  const units = unitsByProperty.filter((unit) => !unit.isModel);
  return !!mapData?.features ? removeUnavailableUnitIDs(mapData, units) : undefined;
};

export const allowedEmailsRegex = RegExp('.+@(irvinecompany\\.com|brivo\\.com|kastle\\.com|mailinator\\.com)');

export const isEmailAllowed = (email: string) => {
  const irvineRegex = allowedEmailsRegex;
  return !_.isEmpty(email) && irvineRegex.test(email);
};

export const parseProspectInfo = (prospect: Prospect) =>
  ({
    ...pick(prospect?.needs, [
      'floorplanGroup',
      'leaseTerm',
      'occupants',
      'moveDate',
      'minRent',
      'maxRent',
      'rentDesired',
      'pets',
      'vehicles',
      'floor',
    ]),
    ...pick(prospect, ['id', 'firstName', 'lastName', 'email', 'phone']),
  } as PivotalData);

export type UnitsVisitedAt = { [key: string]: string };

export const handleVisitedUnits = (mapData: MapData | undefined, unitsVisitedAt: UnitsVisitedAt = {}) => {
  if (!mapData || !Array.isArray(mapData.features)) return mapData;

  const features = mapData?.features?.map((feature) => {
    const properties = feature.properties as MarkerProperties;
    const visitedPostfix = properties.icon?.indexOf(strings.visitedPostfix);
    if (
      unitsVisitedAt &&
      properties.markerType &&
      [MarkerType.Unit, MarkerType.ModelUnit].includes(properties.markerType) &&
      Object.keys(unitsVisitedAt).includes(`${properties?.onesiteId}-${properties?.apartmentId}`) &&
      visitedPostfix === -1
    ) {
      return {
        ...feature,
        properties: {
          ...properties,
          icon: `${properties.icon}${strings.visitedPostfix}`,
        },
      };
    }
    return feature;
  });

  return {
    ...mapData,
    features,
  };
};

export const filterValidTours = (tours: TourMap[] | undefined | null, tourId: string | undefined) =>
  tours?.length ? tours.filter((t) => t.tourId !== tourId && t.mapData?.features) : [];

export const removeVisitedPostfix = (mapData: MapData | undefined) => {
  if (!mapData) return mapData;
  const features = mapData?.features?.map((feature) => {
    const properties = feature.properties as MarkerProperties;
    if (
      properties.markerType &&
      properties.icon &&
      [MarkerType.Unit, MarkerType.ModelUnit].includes(properties.markerType)
    ) {
      const postfixIndex = properties.icon?.indexOf(strings.visitedPostfix);
      if (postfixIndex && postfixIndex > 0) {
        return {
          ...feature,
          properties: {
            ...properties,
            icon: properties.icon.slice(0, postfixIndex),
          },
        };
      }
      return feature;
    }
    return feature;
  });
  return {
    ...mapData,
    features,
  };
};

export const stripBrokenRoutes = (mapData: MapData, brokenIndex: number) => {
  for (let i = 0; i < mapData.features!.length; ) {
    const feature = mapData.features![i];
    const routeProperties = feature.properties as RouteProperties;
    const markerProperties = feature.properties as MarkerProperties;
    // Remove line
    if (typeof routeProperties.route === 'number' && routeProperties.route >= brokenIndex) {
      mapData.features!.splice(i, 1);
      continue;
    }
    // Remove marker route stops
    markerProperties.routeStops = markerProperties.routeStops?.filter((stop) => stop.route! <= brokenIndex);
    i++;
  }
};

export const tourIsExpired = (completedAt: string, startTime: string | undefined | null) =>
  !!(!completedAt && startTime && moment(startTime).diff(moment(), 'hour') < -12);

export const mapDataHasKeylessMarkers = (features: Feature[]) =>
  features?.some((f) => (f.properties as MarkerProperties).isKeyless);

const handleNonKeylessFeature = (feature: Feature) => {
  const properties = feature.properties as MarkerProperties;
  return {
    ...feature,
    properties: {
      ...properties,
      isKeyless: false,
    },
  };
};

export const handleKeylessUnitsOnNonKeylessCommunity = (mapData: MapData | undefined) => {
  const markerFeatures = mapData?.features;
  const nonKeylessFeatures = markerFeatures?.map((f) => {
    const props = f.properties as MarkerProperties;
    if (!props.isKeyless) {
      return f;
    }
    return handleNonKeylessFeature(f);
  });
  return { ...mapData, features: nonKeylessFeatures };
};

export const mapDataHasKeylessUnits = (features: Feature[]) =>
  features?.some((f) => {
    return (isUnit(f) || isModelUnit(f)) && (f.properties as MarkerProperties).isKeyless;
  });

export const handleKeylessUnitsOnAmenityOnlyCommunity = (mapData: MapData | undefined) => {
  const markerFeatures = mapData?.features;
  const nonKeylessFeatures = markerFeatures?.map((f) => {
    const props = f.properties as MarkerProperties;
    if ((isUnit(f) || isModelUnit(f)) && !!props.isKeyless) {
      return handleNonKeylessFeature(f);
    }
    return f;
  });
  return { ...mapData, features: nonKeylessFeatures };
};

/**
 * Handles mapping data with keyless toggles. If the community is set to be non-keyless then all the markers should
 * have keyless at false.
 *
 * @param {Object} options - The options for mapping data.
 * @param {CommunityTile | null | undefined} options.tile - The tile information.
 * @param {MapData | undefined} options.mapData - The map data.
 *
 * @returns {MapData | undefined} - The modified map data with keyless toggles.
 */
export const handleMapDataWithKeylessToggles = ({
  tile,
  mapData,
}: {
  tile: CommunityTile | null | undefined;
  mapData: MapData | undefined;
}): MapData | undefined => {
  if (!mapData?.features) {
    return mapData;
  }

  const keylessModifiedFeatures = mapData.features?.map((f) => {
    const properties = f.properties as MarkerProperties;
    if (!properties.markerType) return f;

    const nonKeylessFeature = {
      ...f,
      properties: {
        ...properties,
        isKeyless: false,
      },
    };

    // Need to remove the keyless property from every marker if the community does not have keyless as true.
    const isKeylessEnabled = tile?.isKeyless;
    if (!isKeylessEnabled) {
      return nonKeylessFeature;
    }

    return f;
  });

  return { ...mapData, features: keylessModifiedFeatures };
};

export const getMarkerKeylessStatus = (markerProperties: MarkerProperties, tile: Tile | null) => {
  const markerType = markerProperties.markerType;
  const isConstructionIcon = markerProperties?.iconBase === 'construction';
  if (
    [MarkerType.Start, MarkerType.Parking, MarkerType.Note, MarkerType.FutureUnit].includes(markerType!) ||
    isConstructionIcon
  ) {
    return {};
  } else if (
    markerType &&
    [MarkerType.Elevator, MarkerType.Wheelchair, MarkerType.Stairs].includes(markerType) &&
    markerProperties?.isKeyless === undefined
  ) {
    // Make these markers non-keyless by default
    return { isKeyless: false };
  } else {
    if (markerProperties?.isKeyless !== undefined) {
      // Return current keyless status
      return { isKeyless: markerProperties?.isKeyless };
    } else if ([MarkerType.Unit, MarkerType.ModelUnit].includes(markerType!)) {
      // Return community keyless status for units/model units
      return { isKeyless: !!tile?.isKeyless && tile?.unitKeylessProvider !== KeylessProvider.NONE };
    } else {
      // Return community keyless status for remaining markers
      return { isKeyless: !!tile?.isKeyless && tile?.amenityKeylessProvider !== KeylessProvider.NONE };
    }
  }
};

export const getKeylessMarkers = (features: Feature[]) => {
  return features?.reduce(
    (a, c) => {
      const properties = c.properties as MarkerProperties;
      const isUnit =
        [MarkerType.Unit, MarkerType.ModelUnit].includes(properties.markerType!) &&
        properties.isKeyless &&
        properties.apartmentId &&
        properties.onesiteId;
      const copy = a;
      const isAmenity =
        isKeylessAccessibleAmenity(properties?.markerType as MarkerType) &&
        properties.isKeyless &&
        properties.isOnRoute;
      if (isUnit) copy.keylessUnits.push(c);
      if (isAmenity) copy.keylessAmenities.push(c);
      return copy;
    },
    { keylessUnits: [] as Feature[], keylessAmenities: [] as Feature[] },
  );
};

// Utility function for deep cloning without enforcing read-only properties
export function deepClone<T>(obj: T): T {
  // Handle non-object types and functions
  if (obj === null || typeof obj !== 'object' || typeof obj === 'function') {
    return obj;
  }

  // Handle Date objects
  if (obj instanceof Date) {
    return new Date(obj.getTime()) as any;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    const clonedArray: any[] = [];
    for (let i = 0; i < obj.length; i++) {
      clonedArray[i] = deepClone(obj[i]);
    }
    return clonedArray as any;
  }

  // Handle objects
  const clonedObj: any = {}; // Cloned object
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone(obj[key]);
      // Make the property writable
      Object.defineProperty(clonedObj, key, {
        writable: true,
        enumerable: true,
        configurable: true,
      });
    }
  }
  return clonedObj as T;
}

export const getConflictingTourIds = (
  keylessData: GetKeylessEntriesByApartmentAndPropertyIDsQuery | null,
  tourId: string,
) => {
  // Retrieve all conflicting entries that match the same prospect/apartment/property that have not errored
  const conflictingEntries = keylessData?.keylessEntriesByPropertyAndApartment?.filter(
    (x) => !x.error && new Date() <= new Date(new Date(x.endTime).setHours(19)) && x.tourId != tourId,
  );

  // Separates out all tourIDs that have a conflicting schedule with the units requested
  const conflictingTourIds = Array.from(new Set(conflictingEntries?.map((x) => x.tourId)));
  return conflictingTourIds;
};

export const recreateUndoList = (mapData: MapData, isMapsindoors: boolean) => {
  if (isMapsindoors) return [mapData];
  const initialState: MapData[] = [
    {
      type: 'FeatureCollection',
      features: [],
    },
  ];
  const featuresSize = mapData.features?.length;
  if (featuresSize && featuresSize > 1) {
    for (let i = 1; i <= featuresSize; i++) {
      if (!mapData.features?.length || !mapData.features[i - 1]) continue;

      const currentFeatureCollection = mapData.features[i - 1];
      const lineCoordinatesLength = currentFeatureCollection.geometry?.coordinates.length;
      const isTypeMarker = currentFeatureCollection.properties?.__typename === 'MarkerProperties';
      const isLineStartAndEndNotBroken = lineCoordinatesLength === 2;
      if (isTypeMarker || isLineStartAndEndNotBroken) {
        initialState.push({
          type: 'FeatureCollection',
          features: mapData.features?.slice(0, i),
        });
      } else {
        let featureCollectionClone = deepClone(currentFeatureCollection);
        const previousFeatures = mapData.features!.slice(0, i - 1);
        const lineFeatures: Feature[] = [];
        for (let j = 0; j < lineCoordinatesLength - 1; j++) {
          const startCoordinates = currentFeatureCollection.geometry?.coordinates[j];
          const endCoordinates = currentFeatureCollection.geometry?.coordinates[j + 1];
          featureCollectionClone.id = `${currentFeatureCollection.id}${j}`;
          featureCollectionClone.geometry!.coordinates = [startCoordinates, endCoordinates];
          lineFeatures.push(featureCollectionClone);
          initialState.push({
            type: 'FeatureCollection',
            features: [...previousFeatures, ...lineFeatures],
          });
          featureCollectionClone = deepClone(featureCollectionClone);
        }
      }
    }
  }
  return initialState;
};

export const checkForErrorsInKeylessUnits = ({
  features,
  keylessEntries,
  setKeylessErrors,
  keylessMutationError,
  tile,
  isMapsIndoorsTour,
}: {
  features: Feature[];
  keylessEntries: Partial<KeylessEntry>[] | undefined;
  setKeylessErrors: (props: KeylessErrorsType) => void;
  keylessMutationError: ApolloError | undefined;
  tile: Tile | null;
  isMapsIndoorsTour?: boolean;
}) => {
  const { keylessUnits, keylessAmenities } = getKeylessMarkers(features);
  const hasSchedulingConflict = !!keylessEntries?.some(
    (entries) => entries.error === strings.KeylessError.schedulingConflict,
  );
  const providerList = [KeylessProvider.KASTLE, KeylessProvider.BRIVO];
  if (tile && tile?.isKeyless) {
    const keylessErrors: KeylessErrorsType[] = [];
    if (keylessUnits.length && tile?.unitKeylessProvider && providerList.includes(tile.unitKeylessProvider)) {
      const keylessIssues = keylessUnits?.reduce(
        (a, c) => {
          const unitApt = (c.properties as MarkerProperties).apartmentId;
          // check that all units on the map have a keyless entry
          // Also checks if keyless entries has errors
          const keylessEntryForUnit = keylessEntries?.find((entry) => unitApt === entry.apartmentId);
          const errors = { ...a };
          if (!keylessEntryForUnit && !a.missingEntries) errors.missingEntries = true;
          if (!!keylessEntryForUnit?.error && !a.hasErrors) errors.hasErrors = true;
          return errors;
        },
        { hasErrors: false, missingEntries: false },
      );
      keylessErrors.push({
        ...keylessIssues,
        hasErrors: keylessIssues.hasErrors || !!keylessMutationError,
        hasSchedulingConflict,
      });
    }
    const isAllBrivo =
      tile?.amenityKeylessProvider === KeylessProvider.BRIVO && tile?.unitKeylessProvider === KeylessProvider.BRIVO;
    const isAllKastle =
      tile?.amenityKeylessProvider === KeylessProvider.KASTLE && tile?.unitKeylessProvider === KeylessProvider.KASTLE;
    const isSingleProvider = isAllBrivo || isAllKastle;
    if (
      (keylessAmenities.length || isMapsIndoorsTour) &&
      tile?.amenityKeylessProvider &&
      providerList.includes(tile?.amenityKeylessProvider) &&
      !isSingleProvider
    ) {
      const leasingOfficeKeylessEntry = keylessEntries?.find(
        (entry) => entry.apartmentId === strings.leasingOfficeApartmentId,
      );
      keylessErrors.push({
        missingEntries: !leasingOfficeKeylessEntry,
        hasErrors: !!leasingOfficeKeylessEntry?.error,
        hasSchedulingConflict,
      });
    }

    const errors = keylessErrors.reduce(
      (a, c) => {
        return {
          missingEntries: c.missingEntries ? c.missingEntries : a.missingEntries,
          hasErrors: c.hasErrors ? c.hasErrors : a.hasErrors,
          hasSchedulingConflict: c.hasSchedulingConflict ? c.hasSchedulingConflict : a.hasSchedulingConflict,
        };
      },
      { hasErrors: false, missingEntries: false, hasSchedulingConflict: false },
    );

    setKeylessErrors({
      missingEntries: errors.missingEntries,
      hasErrors: errors.hasErrors,
      hasSchedulingConflict: errors.hasSchedulingConflict,
    });
  } else {
    setKeylessErrors({
      missingEntries: false,
      hasErrors: false,
      hasSchedulingConflict,
    });
  }
};

export const getCustomNoteFromFeature = (feature: Feature): string | undefined => {
  // Logic for setting the notes for the selected marker
  const selectedFeatureProperties = feature?.properties as MarkerProperties;
  const existingNotes = selectedFeatureProperties?.notes;
  const selectedFeatureFields = selectedFeatureProperties?.mpLocation?.properties?.fields as any;
  let notes: string | undefined;
  // Prefer the existing notes over the ones that come from MapsIndoors CMS
  if (!!selectedFeatureFields && !existingNotes) {
    try {
      // Grab any notes that might come from Maps People
      // For Units the notes is of type object, for Amenities is of type string
      if (typeof selectedFeatureFields !== 'string') {
        notes = (
          selectedFeatureFields as unknown as {
            notes: { value: string };
          }
        ).notes?.value;
      } else {
        const notesObj = JSON.parse(selectedFeatureFields);
        notes = notesObj.notes.value;
      }
    } catch (e) {
      notes = existingNotes ?? undefined;
    }
  } else {
    notes = existingNotes ?? undefined;
  }
  return notes;
};

export const getBuildDuration = (buildTimes: Date[][]) =>
  buildTimes.reduce((acc, cur) => {
    const dur = getDurationInMinutesBetweenTimes(cur[0], cur[1]);
    return acc + dur;
  }, 0);

export const handleUTCKeylessAmenityPropertyId = (communityId: string) => {
  const universityTownCenter = [
    '6f7a0aec-05e3-4fe0-8222-f3e27295adf2', // Stanford Court
    'cc059168-bcc9-4334-8c61-12b924f311d6', // Ambrose
    '60d12487-9ba9-48f5-8545-7ce1dfaa94fd', // Harvard & Cornell Court
    '6fbb64b9-f39a-4538-bac2-7ed265b31ae5', // Berkeley & Columbia Court
  ];
  const utcBase = '57a956ba-d838-40c0-bb6e-2dc90d551838'; // Dartmouth Court
  return universityTownCenter.includes(communityId) ? utcBase : communityId;
};

interface KeylessException {
  __typename?: 'KeylessException';
  communityId?: string | null;
  apartmentId?: string | null;
  propertyId?: string | null;
  metadata?: any | null;
  createdAt?: any | null;
}
/**
 * Updates the isKeyless property of features in the mapData based on the presence of keylessExceptions.
 * If mapData is null or undefined, returns undefined.
 *
 * @param {MapData} mapData - The map data object.
 * @param {Array<KeylessException>} keylessExceptions - The array of keyless exceptions.
 * @returns {MapData | undefined} The updated map data object with modified features or undefined.
 */
export const handleKeylessExceptions = (
  mapData: MapData | undefined,
  keylessExceptions: Array<KeylessException> | undefined,
): MapData | undefined => {
  if (!mapData) return undefined;
  const features = mapData?.features?.map((f) => {
    return handleSingleException(f, keylessExceptions);
  });
  return { ...mapData, features };
};

/**
 * Handles a single exception for a given feature.
 *
 * @param {Feature} f - The feature to handle the exception for.
 * @param {Array<KeylessException> | undefined} keylessExceptions - An array of keyless exceptions, or undefined if there are none.
 * @returns {Feature} - The modified feature after handling the exception.
 */
export const handleSingleException = (f: Feature, keylessExceptions: Array<KeylessException> | undefined) => {
  const { apartmentId, onesiteId, isKeyless } = f.properties as MarkerProperties;
  if (apartmentId && onesiteId && isKeyless) {
    const isKeylessException = keylessExceptions?.some(
      (k) => k.apartmentId === apartmentId && k.propertyId === onesiteId,
    );
    const feature = {
      ...f,
      properties: {
        ...f.properties,
        isKeyless: isKeylessException ? false : isKeyless,
      },
    };
    return feature;
  }
  return f;
};

export const getMarkerProps = (feature?: Feature | null) => {
  if (!feature) return feature;
  return feature?.properties as MarkerProperties;
};
