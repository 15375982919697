import React from 'react';
import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement as ChakraInputRightElement,
} from '@chakra-ui/core';
import { linkHeader } from '../../../constants/typography';
import { CloseSmall, MagnifyingGlass } from '../../icons';
import colors from '../../../constants/colors';

// temp fix for type issue when building. should be fixed when chakra is updated.
const InputRightElement: any = ChakraInputRightElement;

export interface SearchBarProps extends InputProps {
  clearInput: () => void;
  iconSize?: number | undefined;
  preventDefault?: boolean;
}

const SearchBar: React.FC<SearchBarProps> = React.forwardRef(
  ({ clearInput, iconSize = 20, preventDefault, ...rest }, ref) => (
    <InputGroup w="100%">
      <InputLeftElement top="50%" transform="translateY(calc(-50% - 1px)) translateX(calc(50%))" h="20px" w="20px">
        <MagnifyingGlass height={iconSize} width={iconSize} />
      </InputLeftElement>
      <Input
        ref={ref}
        _placeholder={{ textTransform: 'none', ...linkHeader }}
        {...linkHeader}
        width="100%"
        height="56px"
        border="none"
        borderBottom={`1px solid ${colors.primaryGray}`}
        rounded="none"
        p="0 30px 0 40px"
        letterSpacing="-0.5425px"
        fontWeight="500"
        onKeyDown={(e) => {
          if (e.key === 'Backspace') {
            if (e.currentTarget.value.length <= 1) {
              clearInput();
            }
          } else if (e.key === 'Enter' && preventDefault) {
            e.preventDefault();
          }
        }}
        _focus={{ border: '0', borderBottom: `1px solid ${colors.darkGray}` }}
        {...rest}
      />
      <InputRightElement
        as="button"
        type="button"
        w="32px"
        h="32px"
        onClick={clearInput}
        top="50%"
        transform="translateY(-50%)"
      >
        <CloseSmall />
      </InputRightElement>
    </InputGroup>
  ),
);

export default SearchBar;
